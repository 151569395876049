import request from '@/utils/request'
export function handleCommand(e) {
  if (e == 1) {
    this.initWebSocket()
    // this.serviceOnline();
  } else {
    this.serviceOffline();
  }
}

export function serviceOffline() {
  request
    .post("/api/ws/serviceOffline", { sessionId: this.sessionId, app:this.app,serviceCode :this.serviceCode })
    .then(() => {
      this.isOnline = false;
      this.sockClose()
    });
}
export function serviceOnline() {
  request
    .post("/api/ws/serviceOnline", { sessionId: this.sessionId, app:this.app,serviceCode :this.serviceCode })
    .then(() => {
      this.isOnline = true;
    });
}

export function sockClose() {
  if (this.ws.readyState !== 1) return
  this.ws.close()
}

export function isURL(text) {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return urlPattern.test(text);
}

export function upload(a) {
  console.log(a);
  const file = a.file;
  const isAudio = file.type.startsWith("audio/");
  const isVideo = file.type.startsWith("video/");
  const isImage = file.type.startsWith("image/");
  const isDocument = !isAudio && !isVideo && !isImage;
  let contentType;
  if (isAudio) {
    contentType = "audio";
  } else if (isVideo) {
    contentType = "vedio";
  } else if (isImage) {
    contentType = "img";
  } else if (isDocument) {
    contentType = "file";
  }
  console.log(contentType);
  const form = new FormData();
  form.append("displayName", file.name);
  form.append("busiType", "talkMedia");
  form.append("file", file);
  form.append('app',this.app)
  request
    .post("/common/file/upload", form, { "Content-Type": "multipart/form-data" })
    .then((res) => {
      this.clickSend(res.fileUrl, contentType);
    });
}

export function getHistory(contentId) {
  request
    .get("/api/ws/history", {
      customerCode: this.customerCode,
      num: 10,
      contentId,
      app:this.app
    })
    .then((res) => {
      if (res.length < 10) {
        this.isShowMore = false;
      }
      res.reverse();
      this.list.unshift(...res);
      if (this.list.length <= 10) {
        this.scrollToBottom();
      }
    });
}

export function scrollToBottom() {
  this.$nextTick(() => {
    const element = this.$refs.record;
    if(element) {
      element.scrollTop = element.scrollHeight;
    }
  });
}

export function clickSend(content, contentType) {
  if (contentType == "word") {
    if (this.isURL(content)) {
      contentType = "url";
    }
  }
  request
    .post("/api/ws/dialogue", {
      content,
      contentType,
      roomId: this.roomId,
      who: "service",
      customerCode:this.customerCode,
      app:this.app
    })
    .then((res) => {
      this.list.push({
        content,
        contentType,
        createAt: new Date().getTime(),
        contentId: res,
        serviceCode:this.serviceCode,
        who: "service",
      });
      this.send = "";
      this.scrollToBottom();
    });
}

// 未连接的
export function getContent2() {
  request.get("/api/ws/get2Connect", { app:this.app }).then((res) => {
    this.waitCustomerList = res.list;
    this.waitNum = this.waitCustomerList.length
  });
}

// 已连接的
export function getContent() {
  request
    .get("/api/ws/getConnected", { serviceCode: this.serviceCode, app:this.app })
    .then((res) => {
      this.customerList = res;
    });
}

export function websocketOpen() {
  console.log("websocket onopen", this.ws.readyState);
}
export function websocketClose() {
  console.log("websocket onClose", this.ws.readyState);
  if(this.isOnline) {
    console.log(this.isManualDisconnect);
    if(!this.isManualDisconnect) {
      this.reconnect();
    }else {
      this.isManualDisconnect = false
    }
  }
}
export function websocketError() {
  console.log("websocket onerror", this.ws.readyState);
  if(this.isOnline) {
    this.reconnect();
  }
}
export function reconnect() {
  if (this.lockReconnect) {
    return;
  }
  this.lockReconnect = true;
  const that = this;
  // 没连接上会一直重连，设置延迟避免请求过多
  this.tt && clearInterval(this.tt);
  this.tt = setInterval(function () {
    console.log("执行断线重连...");
    that.initWebSocket();
    that.lockReconnect = false;
  }, 4000);
}

export function readMessage() {
  request
    .post("/api/ws/read", { customerCode: this.customerCode, app:this.app,time:new Date(),who:'service' })
    .then((res) => {
      
    });
}
