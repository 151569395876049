<template>
  <div class="im">
    <div
      ref="record"
      class="im-record"
      :style="isHeightValue()"
    >
      <div
        v-if="list.length !== 0"
        class="more"
        @click="getHistory(list[0].contentId)"
      >
        <el-divider content-position="center">{{
          isShowMore ? "更多消息" : "暂无更多消息"
        }}</el-divider>
      </div>
      <div v-for="(item, index) in list" :key="index">
        <div v-if="item.who == 'service'" class="record-item-me">
          <div class="record-item-me-name">{{item.serviceCode == serviceCode ? '我' : item.serviceName}}</div>
          <span
            v-if="item.contentType == 'word'"
            class="record-item-me-text"
            style="white-space: pre-wrap"
            >{{ item.content }}</span
          >
          <span
            v-else-if="item.contentType == 'img'"
            class="record-item-me-text"
          >
            <img class="record-img" :src="item.content" alt="" />
          </span>
          <span
            v-else-if="item.contentType == 'vedio'"
            class="record-item-me-text"
          >
            <video controls class="record-img" :src="item.content"></video>
          </span>
          <span
            v-else-if="item.contentType == 'audio'"
            class="record-item-me-text"
          >
            <audio controls :src="item.content" class="record-img">
              您的浏览器不支持 audio 元素。
            </audio>
          </span>
          <span v-else class="record-item-me-text">
            <a target="_blank" :href="item.content">{{ item.content }}</a>
          </span>
        </div>
        <div v-else class="record-item-other">
          <div class="record-item-other-name">
            {{ item.customerName || item.name }}
          </div>
          <span
            v-if="item.contentType == 'word'"
            class="record-item-other-text"
            style="white-space: pre-wrap"
            >{{ item.content }}</span
          >
          <span
            v-else-if="item.contentType == 'img'"
            class="record-item-other-text"
          >
            <img class="record-img" :src="item.content" alt="" />
          </span>
          <span
            v-else-if="item.contentType == 'vedio'"
            class="record-item-other-text"
          >
            <video controls class="record-img" :src="item.content"></video>
          </span>
          <span
            v-else-if="item.contentType == 'audio'"
            class="record-item-other-text"
          >
            <audio controls :src="item.content" class="record-img">
              您的浏览器不支持 audio 元素。
            </audio>
          </span>
          <span v-else class="record-item-me-text">
            <a target="_blank" :href="item.content">{{ item.content }}</a>
          </span>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="im-send-phone" ref="phone">
      <div style="flex: 1; margin-right: 10px">
        <!-- type="textarea" -->
        <el-input v-model="send" @input="changeValue" :autosize="{minRows: 1, maxRows: 3}" type="textarea" placeholder="请输入内容" />
      </div>
      <div class="im-send-phone-btn" @click="clickSend(send, 'word')">发送</div>
      <img
        @click="clickShowTools"
        src="@/assets/images/icon_new_add.png"
        class="im-send-phone-tools"
        alt=""
        srcset=""
      />
    </div>
    <div v-if="isShowTools" class="im-send-phone-tool">
      <el-upload
        :show-file-list="false"
        action=""
        :http-request="upload"
        :limit="1"
      >
        <div class="send-tools-item">
          图片
        </div>
        <!-- <img src="@/assets/images/tupian.png" class="tools-img" alt="" /> -->
      </el-upload>
      <div class="send-tools-item" @click="showReply = true">
        快捷回复
      </div>
      <div class="send-tools-item" @click="visible = true">转接</div>
    </div>
    <!-- 快捷回复 -->
    <div class="reply" v-if="showReply">
      <div class="reply-left">
        <div
          :class="[
            'reply-left-item',
            activeIndex == index ? 'acitve-item' : '',
          ]"
          v-for="(item, index) in answerTree"
          :key="index"
          @click="onClick(index)"
        >
          {{ item.groupName }}
        </div>
      </div>
      <div class="reply-right">
        <div
          class="reply-right-item"
          v-for="(item1, index1) in answerTree[activeIndex]
            .fastAnswerItemDtoList"
          :key="index1"
        >
          <div class="reply-right-item-label" @click="clickAnswer(item1.answer)">
            {{ item1.answer }}
          </div>
        </div>
      </div>
    </div>
    <div class="support">
      佳鲸通提供客服软件支持
    </div>

    <el-drawer
      title="转接对象"
      :visible.sync="visible"
      direction="btt"
      :before-close="handleClose"
      size="50%">
      <div class="drawer-list">
        <div v-for="(item,index) in serviceList" :key="index" class="drawer-list-item" @click="clickService(item)">
          <img src="@/assets/images/ren.png" class="per-icon" alt="" srcset="">
          {{ item.serviceName }}
        </div>
      </div>
    </el-drawer>
    <el-drawer
      title="转接对象"
      :visible.sync="visibleService"
      direction="btt"
      :before-close="handleServiceClose"
      size="30%"
      :withHeader="false"
      :show-close="false">
      <div class="drawer-header">
        <div class="drawer-header-left" @click="onBack">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="drawer-header-center">转接给</div>
        <div class="drawer-header-right" @click="transfer" style="opacity: 0;">转接</div>
      </div>
      <div>
        <div class="drawer-list-item" style="border: 0;padding: 0 20px;">
          <img src="@/assets/images/ren.png" class="per-icon" alt="" srcset="">
          {{ selectService.serviceName }}
        </div>
      </div>
      <div class="drawer-footer">
        <div class="drawer-footer-btn gery" @click="visibleService = false">
          取消
        </div>
        <div class="drawer-footer-btn" @click="transfer">
          确定
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import request from "@/utils/request";
import {
  upload,
  websocketOpen,
  websocketClose,
  websocketError,
  reconnect,
  getHistory,
  isURL,
  clickSend,
  scrollToBottom,
  serviceOnline,
  readMessage
} from "@/components/serviceIm/index";
export default {
  data() {
    return {
      sessionId: null,
      isShowMore: true,
      send: "",
      isShowTools: false,
      roomId: "",
      list: [],
      app: "",
      customerCode: "",
      answerTree: [],
      serviceCode: '',
      activeIndex: 0,
      showReply:false,
      isManualDisconnect: false,
      rows:1,
      phoneHeight: '50',
      visible: false,
      serviceList: [],
      customerName: '',
      visibleService: false,
      selectService:{}
    };
  },
  methods: {
    upload,
    getHistory,
    websocketOpen,
    websocketClose,
    websocketError,
    reconnect,
    isURL,
    clickSend,
    scrollToBottom,
    serviceOnline,
    readMessage,
    onBack() {
      this.visible = true
      this.visibleService = false
    },
    clickService(item) {
      this.selectService = item
      this.visibleService = true
      this.visible = false
    },
    handleServiceClose() {
      this.visibleService = false
    },
    transfer() {
      const form = {
        app:this.app,
        customerCode:this.customerCode,
        customerName: this.customerName,
        roomId: this.roomId,
        serviceCode: this.selectService.serviceCode
      }
      request.post('/api/ws/transfer',form).then(res => {
        this.visible = false
        this.$message.success({
          message:"转接成功",
          iconClass:'message-class',
          customClass: 'custom-class'
        })
        this.visibleService = false
        setTimeout(() => {
          this.$router.go(-1)
        },1000)
      })
    },
    handleClose() {
      this.visible = false
    },
    changeValue() {
      const myElement = this.$refs.phone;
      this.phoneHeight = myElement.offsetHeight;
      console.log(this.phoneHeight);
    },
    clickShowTools() {
      this.isShowTools = !this.isShowTools
      this.showReply = false
    },
    isHeightValue() {
      if(this.isShowTools) {
        if(this.showReply) {
          return `height: calc(100% - 40px - 200px - 20px - ${this.phoneHeight}px) !important;`
        }else {
          return `height: calc(100% - 40px - 35px - ${this.phoneHeight}px) !important;`
        }
      }else {
        return `height: calc(100% - ${this.phoneHeight}px - 30px);`
      }
    },
    clickAnswer(answer) {
      this.clickSend(answer,'word')
      this.showReply = false
    },
    onClick(index) {
      this.activeIndex = index;
    },
    initWebSocket() {
      const that = this;
      this.ws = new WebSocket(this.$wsUrl);
      this.ws.onmessage = function (event) {
        that.tt && clearInterval(that.tt);
        const data = JSON.parse(event.data);
        if (data.type == "session") {
          that.sessionId = data.data;
          that.serviceOnline();
        }
        if (data.type == "content") {
          data.data.who = "user";
          if (that.roomId == data.data.roomId) {
            that.list.push(data.data);
            that.readMessage()
          }
          that.scrollToBottom();
        }
        console.log(`收到消息：${event.data}`);
      };
      this.ws.onopen = this.websocketOpen;
      this.ws.onerror = this.websocketError;
      this.ws.onclose = this.websocketClose;
    },
    getAnswerTree() {
      request
        .get(
          `/api/answer/getAnswerTree?app=${this.app}&serviceCode=${this.serviceCode}`
        )
        .then((res) => {
          this.answerTree = res;
        });
    },
    getServiceInfo() {
      request.get('/api/service/getServiceInfo',{app:this.app}).then(res => {
        res.forEach(item => {
          res.check = false
        })
        this.serviceList = res
      })
    }
    
  },
  mounted() {
    this.roomId = this.$route.query.roomId;
    this.app = this.$route.query.app;
    this.customerCode = this.$route.query.customerCode;
    this.serviceCode = this.$route.query.serviceCode;
    this.customerName = this.$route.query.name;
    document.title = this.$route.query.name
    this.getHistory();
    this.initWebSocket();
    this.getAnswerTree()
    this.getServiceInfo()
  },
  beforeDestroy() {
    this.isManualDisconnect = true
    this.ws.close()
    
  },
};
</script>

<style lang="scss" scoped>
.im {
  height: 100vh;
  font-size: 18px;
}
.im-record {
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
  overflow: auto;
  padding: 10px;
  .more {
    margin-bottom: 10px;
    cursor: pointer;
    ::v-deep .el-divider__text {
      color: #999;
    }
  }
  .record-item-me {
    width: auto;
    box-sizing: content-box;
    margin-left: auto;
    margin-bottom: 10px;
    max-width: calc(100% - 40px);
    text-align: right;
  }
  .record-item-other {
    max-width: calc(100% - 40px);
    margin-bottom: 10px;
  }
  .record-item-other-name,
  .record-item-me-name {
    margin-bottom: 5px;
    color: #999;
    font-size: 12px;
  }
  .record-item-other-text {
    display: inline-block;
    background-color: #f3f4f7;
    padding: 5px;
    border-radius: 0 5px 5px 5px;
  }
  .record-item-me-text {
    display: inline-block;
    background-color: #6493fc;
    color: #fff;
    padding: 5px;
    border-radius: 5px 0 5px 5px;
  }
  .record-img {
    max-width: 100%;
  }
}
.im-send-phone {
  background-color: #fff;
  display: flex;
  padding: 10px 10px;
  align-items: center;
  box-sizing: border-box;
  ::v-deep .el-input__inner {
    background-color: #f2f3f6;
    font-size: 17px !important;
  }
  &-btn {
    line-height: 32px;
    text-align: center;
    width: 50px;
    height: 32px;
    border-radius: 5px;
    background-color: #43b4ea;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
  }
  &-tools {
    width: 32px;
    height: 32px;
  }
  &-tool {
    padding: 10px;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
  }
}
.tools-img {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.reply {
  font-size: 16px;
  display: flex;
  height: 200px;
  &-left {
    background-color: #f2f2f2;
    height: 100%;
    overflow-y: auto;
    &-item {
      padding: 10px 20px;
    }
  }
  .acitve-item {
    background-color: #fff;
  }
  &-right {
    overflow-y: auto;
    flex: 1;
    width: 0;
    box-sizing: border-box;
    &-item {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dcdfe6;
      &-label {
        flex: 1;
        margin-right: 10px;
        overflow: hidden; 
        text-overflow: ellipsis; 
        white-space: nowrap;
      }
    }
  }
}
.support {
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #999;
  font-size: 12px;
}
.send-tools-item {
  border: 1px solid #999;
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 40px;
  color: #999;
  cursor: pointer;
}

.drawer-header {
  font-size: 16px;
  display: flex;
  padding:20px;
  height: 70px;
  box-sizing: border-box;
  align-items: center;
  &-center {
    flex: 1;
    text-align: center;
    font-weight: bold;
  }
  &-right {
    color: #fff;
    background-color: #07c160;
    padding: 6px 15px;
    border-radius: 5px;
  }
}
.drawer-list {
  padding: 0 20px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  &-item {
    display: flex;
    height: 60px;
    align-items: center;
    border-bottom: 1px solid #f6f6f6;
  }
}
.per-icon {
  margin-right: 20px;
  width: 32px;
  height: 32px;
}
.drawer-footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  &-btn {
    padding: 10px 20px;
    background-color: #07c160;
    color: #fff;
    border-radius: 6px;
  }
  .gery {
    background-color: #F3F0F3;
    color: #07c160;
  }
}
.message-class {
  font-size: 16px;
}
.custom-class {
  color: #43b4ea !important;
}
</style>
